import { InnerPageWrapper } from '#/src/app/(application)/_components/InnerPageWrapper'
import { InnerPageHeader } from '#/src/app/(application)/_components/InnerPageHeader'
import { Box, Button, Heading, Text } from '@chakra-ui/react'
import { AppLink } from 'ui'
import { IoReload } from 'react-icons/io5'
import { usePathname } from 'next/navigation'

export default function NextErrorPage({
  error,
  reset
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const pathname = usePathname()

  const isVisibleHomeLink = !['/', '/app'].includes(pathname)

  return (
    <InnerPageWrapper alignItems="center" textAlign="center">
      <InnerPageHeader />

      <Heading variant="main">Oops!</Heading>

      <Text>
        En error has occurred and we&apos;re working to fix the problem!
        <br />
        We&apos;ll be up and running shortly.
      </Text>

      <Text>Error: {error.message}</Text>

      <Button width="80%" variant="outline" onClick={reset} leftIcon={<IoReload />}>
        Try again
      </Button>

      {isVisibleHomeLink && (
        <>
          <Box>or</Box>

          <Button as={AppLink} href="/app" width="80%">
            Return Home
          </Button>
        </>
      )}
    </InnerPageWrapper>
  )
}
