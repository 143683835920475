import { StackProps, VStack } from '@chakra-ui/react'

type InnerPageWrapperProps = StackProps

export const InnerPageWrapper = (props: InnerPageWrapperProps) => {
  return (
    <VStack
      alignItems="stretch"
      gap={10}
      id="inner-page-wrapper"
      p={4}
      pb={16}
      pt={2}
      // h="full"
      w="full"
      {...props}
    />
  )
}
