import type { ReadonlyURLSearchParams } from 'next/navigation'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useMemo } from 'react'
import { usePrevious } from '@chakra-ui/react'
import { isUndefined } from 'shared-utils'
import { useRefUpdated } from './useRefUpdated'

export type UseRouterChangedCbParams = {
  data: {
    pathname: string
    prevPathname?: string
    searchParams: ReadonlyURLSearchParams
    prevSearchParams?: ReadonlyURLSearchParams
    fullPath: string
    prevFullPath?: string
  }
  meta: {
    isInitialLoad: boolean
    isPathChanged: boolean
    isSearchParamsChanged: boolean
  }
}

export const useRouterChanged = (cb: (params: UseRouterChangedCbParams) => void) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const fullPath = useMemo(() => {
    const searchParamsString = searchParams.toString()

    return searchParamsString ? `${pathname}?${searchParamsString}` : pathname
  }, [pathname, searchParams])
  const cbRef = useRefUpdated(cb)

  const prevPathname = usePrevious(pathname)
  const prevSearchParams = usePrevious(searchParams)
  const prevFullPath = usePrevious(fullPath)

  useEffect(() => {
    if (cbRef.current) {
      const isInitialLoad = isUndefined(prevPathname) && isUndefined(prevSearchParams)

      cbRef.current({
        data: {
          pathname,
          prevPathname,
          searchParams,
          prevSearchParams,
          fullPath,
          prevFullPath
        },
        meta: {
          isInitialLoad,
          isPathChanged: !isInitialLoad && prevPathname !== pathname,
          isSearchParamsChanged:
            !isInitialLoad && prevSearchParams?.toString?.() !== searchParams.toString()
        }
      })
    }
  }, [cbRef, fullPath, pathname, prevFullPath, prevPathname, prevSearchParams, searchParams])
}
