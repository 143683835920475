'use client'

import { Button, ButtonProps, Heading, HStack, Icon, Spacer, VStack } from '@chakra-ui/react'
import { AppLink } from 'ui'
import { MouseEvent, ReactNode, useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/navigation'
import { BiArrowBack } from 'react-icons/bi'
import { useRouterChanged } from 'ui/hooks/useRouterChanged'

type InnerPageHeaderProps = {
  title?: ReactNode
  href?: string
  // will be used when it's not possible to go back (there is no browser history yet)
  fallbackHref?: string
  rightAddon?: ReactNode
  backButtonProps?: Partial<ButtonProps> & {
    isHidden?: boolean
  }
}

export const InnerPageHeader = ({
  title,
  href,
  fallbackHref = '/app',
  rightAddon,
  backButtonProps: {
    isHidden: isHideBackButton,
    onClick: onClickBackButton,
    ...backButtonProps
  } = {}
}: InnerPageHeaderProps) => {
  const router = useRouter()
  const tryToGoBackTimer = useRef<number>(NaN)

  useRouterChanged(({ meta: { isPathChanged, isSearchParamsChanged } }) => {
    if (isPathChanged || isSearchParamsChanged) {
      clearTimeout(tryToGoBackTimer.current)
    }
  })

  useEffect(() => {
    return () => clearTimeout(tryToGoBackTimer.current)
  }, [])

  const routerGoBack = useCallback(() => {
    clearTimeout(tryToGoBackTimer.current)

    router.back()

    tryToGoBackTimer.current = setTimeout(() => {
      router.replace(fallbackHref)
    }, 1000) as unknown as number
  }, [fallbackHref, router])

  const handleOnClickBackButton = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (onClickBackButton) {
        onClickBackButton(e)

        return
      }

      if (href) {
        return
      }

      routerGoBack()
    },
    [routerGoBack, href, onClickBackButton]
  )

  return (
    <VStack gap={2} w="full">
      <HStack justifyContent="space-between" w="full">
        {!isHideBackButton ? (
          <Button
            aria-label="back"
            color="text.default"
            fontSize="md"
            leftIcon={<Icon as={BiArrowBack} boxSize={4} />}
            onClick={handleOnClickBackButton}
            p={0}
            variant="ghost"
            {...(href
              ? {
                  href,
                  as: AppLink
                }
              : {})}
            {...backButtonProps}>
            {'children' in backButtonProps ? backButtonProps.children : 'Back'}
          </Button>
        ) : (
          <Spacer flex={1} />
        )}
        {rightAddon}
      </HStack>
      {!!title && (
        <Heading variant="main" w="full">
          {title}
        </Heading>
      )}
    </VStack>
  )
}
